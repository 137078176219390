import React from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

const Redirect404 = () => {
  if (typeof window !== 'undefined') {
    navigate('/');
  }
  /**
   * @todo insert SEO
   */
  return (
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
};

export default Redirect404;
